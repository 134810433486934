import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

class HomeBanner extends Component {
  state = {};
  render() {
    const {
        popularLiveTvData,
        loadingContent,
        loadingpopularLiveTvData,
    } = this.props;
    return (
        <div className="home-banner-sec">

            {loadingpopularLiveTvData ? (
                "Loading..."
            ) : popularLiveTvData.length > 0 ? (

            <Carousel
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}
                showArrows={true}
                showIndicators={false}
                autoPlay={false}
                stopOnHover={true}
                swipeable={true}
            >
            {popularLiveTvData.map(liveTv => (
                <div className="carousel fade-carousel slide carousel-fade" key={liveTv.custom_live_video_id}>
                    <div className="row m-0">
                       
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="carousel-inner relative">
                                <img
                                    className="banner_right_img"
                                    src={liveTv.image}
                                    srcSet={
                                        liveTv.image +
                                        " 1x," +
                                        liveTv.image +
                                        " 1.5x," +
                                        liveTv.image +
                                        " 2x"
                                    }
                                    alt="banner img"
                                />
                                <div className="banner_right_overlay" />
                            </div>
                        </div>
                    </div>
                    <div className="banner-content">
                        <div className="hero">
                            <div>
                                <h1 className="banner_video_title">
                                    {liveTv.title}
                                </h1>
                                <h4 className="banner_video_text">
                                    {liveTv.description}
                                </h4>
                                <div className="banner-btn-sec">
                                <Link
                                to={{
                                    pathname: `/live-tv/single-view/${liveTv.title}`,
                                    state: liveTv,
                                }}
                                className="btn btn-home-primary"
                                >Play</Link>

                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </Carousel>

            ) : ''}
        </div>
    );
  }
}

export default HomeBanner;
