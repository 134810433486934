import React, { Component } from "react";

class ObsUrlModel extends Component {
    state = {};
    render() {
        const {
            liveVideoData,
            loadingLiveVideo,
            modelButtonDisabled,
            startLiveAPI,
            modelLoadingContent,
        } = this.props;
        
        return (
            <div
                className="modal fade modal-index login-modal"
                id="obs_url_modal"
                role="dialog"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                            <h4 className="modal-title">OBS Url's</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form onSubmit={startLiveAPI}>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Streaming Url</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Streaming Url"
                                            name="title"
                                            value={loadingLiveVideo
                                                ? ""
                                                : liveVideoData.streaming_url}
                                            readOnly
                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Key</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Streaming key"
                                            name="title"
                                            value={loadingLiveVideo
                                                ? ""
                                                : liveVideoData.virtual_id}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="modal-btn">
                                    <button
                                        type="submit"
                                        className="btn"
                                        disabled={modelButtonDisabled}
                                        onClick={(event) => startLiveAPI(event, liveVideoData)}
                                    >
                                        {modelLoadingContent != null
                                            ? modelLoadingContent
                                            : "Start BroadCasting"}
                                    </button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ObsUrlModel;
