import React, { Component } from "react";
import HomeSec from "./HomeSec";
import HomePublic from "./HomePublic";
import HomePrivate from "./HomePrivate";
import HomeTabSec from "./HomeTabSec";
import HomeRightSideSec from "./HomeRightSideSec";
import Sidebar from "../layouts/sidebar/Sidebar";
import HomeBanner from "./HomeBanner";
import HomePublicList from "./HomePublicList";
import HomePrivateList from "./HomePrivateList";
import api from "../../Environment";

class HomeIndex extends Component {
  state = {
    liveVideoData: null,
    loadingLiveVideo: true,
    skipCountLiveVideo: 0,
    liveVideoPublicData: null,
    loadingLiveVideoPublic: true,
    skipCountLiveVideoPublic: 0,
    liveVideoPrivateData: null,
    loadingLiveVideoPrivate: true,
    skipCountLiveVideoPrivate: 0,
    loadMoreButtonDisable: false,
    loadingContent: null,
    popularLiveTvData: null,
    loadingpopularLiveTvData: true,
    skipCountPopularVodVideo: 0,
    userSuggesstionData: null,
    loadingUserSuggesstionData: true,
    skipCountUserSuggesstion: 0,

  };

  componentDidMount() {
    const inputData = {
      skip: this.state.skipCountLiveVideo,
    };
    this.getLiveVideoPublicDetails();
    this.getLiveVideoPrivateDetails();
    this.getBannerVodVideo();
  }

  loadMore = (event, type) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: "Loading...",
    });
    
  };

  getLiveVideoPublicDetails = () => {
    const inputData = {
      skip: this.state.skipCountLiveVideoPublic,
    };
    if (this.state.liveVideoPublicData == null)
      this.getLiveVideoPublicAPI(inputData);
  };

  getLiveVideoPublicAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_public", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.liveVideoPublicData != null) {
          items = [...this.state.liveVideoPublicData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        this.setState({
          liveVideoPublicData: items,
          loadingLiveVideoPublic: false,
          skipCountLiveVideoPublic:
            response.data.data.length + this.state.skipCountLiveVideoPublic,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  getLiveVideoPrivateDetails = () => {
    const inputData = {
      skip: this.state.skipCountLiveVideoPrivate,
    };
    if (this.state.liveVideoPrivateData == null)
      this.getLiveVideoPrivateAPI(inputData);
  };

  getLiveVideoPrivateAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_private", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.liveVideoPrivateData != null) {
          items = [...this.state.liveVideoPrivateData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        this.setState({
          liveVideoPrivateData: items,
          loadingLiveVideoPrivate: false,
          skipCountLiveVideoPrivate:
            response.data.data.length + this.state.skipCountLiveVideoPrivate,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  getBannerVodVideo = () => {
    const inputData = {
      skip: this.state.skipCountPopularVodVideo,
    };
    if (this.state.popularLiveTvData == null)
      this.getBannerVodVideoAPI(inputData);
  };
  getBannerVodVideoAPI = (inputData) => {
    let items;
    api.postMethod("home_banner_section", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.popularLiveTvData != null) {
          items = [...this.state.popularLiveTvData, ...response.data.data.custom_live_videos];
        } else {
          items = [...response.data.data.custom_live_videos];
        }
        this.setState({
          popularLiveTvData: items,
          loadingpopularLiveTvData: false,
          skipCountPopularVodVideo:
            response.data.data.length + this.state.skipCountPopularVodVideo,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  render() {
    const {
      liveVideoPrivateData,
      liveVideoPublicData,
      loadMoreButtonDisable,
      loadingContent,
      loadingLiveVideoPrivate,
      loadingLiveVideoPublic,
      loadingpopularLiveTvData,
      popularLiveTvData,
    } = this.state;
    return (
      <div class="main video-stream">
        <div class="container-fluid">
          <div class="row">
            <div class="stn-live-video" id="above-full">
              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 zero-padding"
                id="full-view"
              >
                <HomeBanner
                  popularLiveTvData={popularLiveTvData}
                  loadingpopularLiveTvData={loadingpopularLiveTvData}
                  loadingContent={loadingContent}
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid top-padding-sec">
          <h1 className="section-title">Public</h1>
          <div className="row">
            <HomePublic
              liveVideoPublicData={liveVideoPublicData}
              loadingLiveVideoPublic={loadingLiveVideoPublic}
              loadingContent={loadingContent}
              loadMoreButtonDisable={loadMoreButtonDisable}
            />
          </div>
        </div>
        <div className="container-fluid top-padding-sec">
          <h1 className="section-title">Private</h1>
          <div className="row">
            <HomePrivate
              liveVideoPrivateData={liveVideoPrivateData}
              loadingLiveVideoPrivate={loadingLiveVideoPrivate}
              loadingContent={loadingContent}
              loadMoreButtonDisable={loadMoreButtonDisable}
            />
          </div>
          
        </div>
      </div>
    );
  }
}

export default HomeIndex;
