import React, { Component } from "react";
import BroadcastHelper from "./BroadcastHelper";
import api from "../../Environment";
import ToastContent from "../helper/ToastContent";
import { withToastManager } from "react-toast-notifications";
import ReactPlayer from "react-player";
import Sidebar from "../layouts/sidebar/Sidebar";
import BroadcastChat from "./BroadcastChat";
import io from "socket.io-client";
import config from "react-global-configuration";
const $ = window.$;
let chatSocket;

class ObsLive extends BroadcastHelper {
  player = {};

  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    chatData: null,
    loadingChatData: true,
    loadMoreButtonDisable: false,
    loadingContent: null,
    liveVideoData: null,
  };

  
  componentDidMount() {
    this.setState({
      liveVideoData: this.props.location.state,
    });
  }

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_chat", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + this.state.liveVideoData);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.state.liveVideoData.virtual_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.state.liveVideoData.virtual_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
    }
  };

  handleChatSubmit = (event) => {
    event.preventDefault();
    const type =
      this.state.liveVideoData.user_id == localStorage.getItem("userId")
        ? "uv"
        : "vu";
    let chatData = [
      {
        live_video_id: this.state.liveVideoData.live_video_id,
        user_id: this.state.liveVideoData.user_id,
        live_video_viewer_id: localStorage.getItem("userId"),
        message: this.state.chatInputMessage,
        type: type,
        user_name: localStorage.getItem("username"),
        user_picture: localStorage.getItem("user_picture"),
      },
    ];
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket.emit("message", chatData[0]);
    }
    let messages;
    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      chatInputMessage: "",
    });
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  stopStreaming = (event) => {

    this.setState({ subscribers: [] });
    this.stopBroadcastAPI();
  };

  stopBroadcastAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_details.id,
    };
    api
      .postMethod("live_videos_broadcast_stop", inputData)
      .then((response) => {
        if (response.data.success) {
          ToastContent(
            this.props.toastManager,
            response.data.message,
            "success"
          );
          this.setState({ apiCallstatus: true });
        } else {
          ToastContent(this.props.toastManager, response.data.error, "error");
        }
      });
      window.location.replace("/");
  };

  render() {
    const liveVideoData = this.props.location.state;
    const {
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    
    console.log(liveVideoData.live_video_details);
    return (
      
      <div class="main">
      {this.state.loadingStreamingPage ? (
        <div className="no-data-found-img">
          <div className="Spacer-10"></div>
          <img
            src={
              window.location.origin +
              "/assets/img/fidget-spinner-loading.gif"
            }
          ></img>
        </div>
      ) : (
        // "Getting details... Please wait.."
        <div className="sec-padding">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 top-margin">
              <div className="relative">
                  <ReactPlayer
                    ref={this.ref}
                    url={liveVideoData.live_video_details.video_url}
                    controls={true}
                    width="100%"
                    height="100%"
                    playing={true}
                  />
                  <button
                    type="submit"
                    className="btn"
                    type="button"
                    onClick={() =>
                      this.stopStreaming()
                    }
                  >
                    Stop streaming
                  </button>
              </div>
            </div>
            
            <BroadcastChat
              loadMore={this.loadMore}
              chatData={chatData}
              loadingChatData={loadingChatData}
              loadingContent={loadingContent}
              loadMoreButtonDisable={loadMoreButtonDisable}
              chatInputMessage={this.state.chatInputMessage}
              chatInputChange={this.chatInputChange}
              handleChatSubmit={this.handleChatSubmit}
            />
          </div>
        </div>
      )}
      
    </div>
    );
  }
}

export default ObsLive;
