export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Wishlist status

  WISHLIST_CLEAR_ALL: 1,

  WISHLIST_CLEAR_SINGLE_AUDIO: 0,

  // History status

  HISTORY_CLEAR_ALL: 1,

  HISTORY_CLEAR_SINGLE_AUDIO: 0,

  // PAGE TYPES

  PRIVACY_PAGE_TYPE: "privacy",

  TERMS_PAGE_TYPE: "terms",

  // Social Login Credentials

  GOOGLE_CLIENT_ID: "",

  FACEBOOK_APP_ID: "",

  JWPLAYER_KEY: "M2NCefPoiiKsaVB8nTttvMBxfb1J3Xl7PDXSaw==",

  USER_API_CALL: "/user",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://admin-obs.appswamy.com/api/user/get_settings_json",

  GOOGLE_CLIENT_ID:
      "603748490991-lta5s2ju9krs16uvq0thb2f5rjohpqbd.apps.googleusercontent.com",

  FACEBOOK_APP_ID: "285371778766319",

  JWPLAYER_KEY: "M2NCefPoiiKsaVB8nTttvMBxfb1J3Xl7PDXSaw==",

  // Hisotry status

  HISTORY_INITIATE_STATUS: 1,

  HISTORY_COMPLETE_STATUS: 2,

  HISTORY_DELETE_STATUS: 3,

  USER_API_CALL: "/user",

  HOST_API_CALL: "/provider",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://admin-obs.appswamy.com/api/user/get_settings_json",

  google_api_key: "AIzaSyARW_YBJ-OU_RfSlMLlvLBHJaG-W_EQv4I",

  socketUrl: "https://admin-obs.appswamy.com:3000/",

};
