import React, { Component } from "react";
import Sidebar from "../layouts/sidebar/Sidebar";
import "./Refferral.css";
import { Link } from "react-router-dom";

class Refferral extends Component {
    render() {
        return (
            <div className="main">
                <Sidebar />
                <div className="sec-padding refferral-sec left-spacing1">
                    <div className="row small-padding">
                        <div className="public-video-header">Refferral</div>
                        <div className="Spacer-9"></div>
                        <div className="col-md-7">
                            <div className="refer-box">
                                <span className="item-arrow"></span>
                                <h2>Spread the word and earn rewards</h2>
                                <p>When someone signs up with your unique referral link, you will get<span>$10</span></p>
                                <h4>Click to Copy Invite Link</h4>
                                <form>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="https://streamnow.com/rc/5f5a69a3c77c4" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="refer-right-box">
                                <div className="refer-right-header">
                                    <h4>REFFERRAL</h4>
                                </div>
                                <div className="refer-right-body">
                                    <div className="card-body">
                                        <p>Total <span class="pull-right"><b>1</b></span></p>
                                        <hr />

                                        <p>Earnings <span class="pull-right"> <b>$ 10</b></span></p>
                                        <hr />
                                    </div>
                                    <div className="card-footer">
                                        <a href="#" class="btn btn-primary text-uppercase"><i class="fa fa-money"></i> CHECK REDEEMS</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Spacer-9"></div>
                    <h4>REFFERRALS</h4>
                    <hr></hr>
                    <div className="refer-table">
                        <div className="row">
                            <div className="col-md-2">
                                <img src="assets/img/placeholder-profile.jpg" className="user-img" />
                            </div>
                            <div className="col-md-3">
                                <h4>Beno Darry</h4>
                            </div>
                            <div className="col-md-3">
                                <h4>5f5a69a3c77c4</h4>
                            </div>
                            <div className="col-md-2">
                                <h4>20 Sep 2020</h4>
                            </div>
                            <div className="col-md-2">
                                <div className="align-right">
                                    <a href="#" class="btn btn-info">
                                        More <i class="fa fa-angle-right"></i><i class="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Refferral;
