import React, { Component } from "react";
import "./HomeBanner.css";
import { Link } from "react-router-dom";
import Background from "./church-5.jpg";

class HomePrivateList extends Component {
  state = {};
  render() {
    return (
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 top-margin-sm">
        <div class="live-video-box">
          <div
            class="public-img"
            style={{
              background: `url(${Background})`,
            }}
          >
            <Link to="#">
              <div class="playbtn1">
                <div class="white-btn-play1">
                  <img src="assets/img/play-btn.png" />
                </div>
                <div class="pink-btn-play1">
                  <img src="assets/img/play-btn-pink.png" />
                </div>
              </div>
            </Link>
            <div class="">
              <div class="ribbon">
                <h4 class="head">$10</h4>
              </div>
            </div>
            <a href="#">
              <div class="followers-count">
                <i class="fa fa-eye icon"></i>
                <span>2 view</span>
              </div>
            </a>
          </div>
          <div class="user-profile spacing">
            <h4 class="h4-s user-name text-bold overflow">Beno darry</h4>
            <h5 class="h5-s user-name overflow">Hello world</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePrivateList;
